import { useEffect, useRef } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { FutureResult } from 'model/FutureResult'
import { NOT_SUPPORTED } from 'utils/path'
import { getPath } from 'api/CommonApi'
import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { setVin } from 'features/vin/vin-slice'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'
import { ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'

export const useLang = (): string | undefined => useMatch('/:lang/*')?.params.lang

export const useVinFromState = (): string => {
  const vin = useAppSelector((state) => state.vin.value)

  const dispatch = useAppDispatch()
  const location = useLocation()

  if (vin) {
    return vin
  }

  const updatedVin = new URLSearchParams(location.search).get('vin') ?? ''
  if (updatedVin) {
    dispatch(setVin(updatedVin))
  }

  return updatedVin
}

export const useHandleErrorRetry = (): void => {
  const lang = useLang()
  const [count, setCount] = useLocalStorage('ERROR_PAGES_VIST_COUNT', 0)
  const countRef = useRef(count)
  useEffect(() => {
    setCount(countRef.current + 1)
    if (countRef.current >= 1) {
      setCount(0)
      window.location.assign(`/${lang}${window.location.search}`)
    }
  }, [lang, setCount])
}

export const useIsMobileMode = (): boolean => {
  const MOBILE_BREAKPOINT = 760
  const { width } = useWindowSize()

  return width < MOBILE_BREAKPOINT
}

export const useConnectivityProductUIStateFromStore = (): FutureResult<ConnectivityProductUIState> => {
  return useAppSelector((state) => state.connectivityProductUIState.value)
}

export const usePath = (connectivityProductState: FutureResult<ConnectivityProductUIState>): string => {
  const navigate = useNavigate()
  const search = useLocation().search
  let lang = useLang()
  const pathname = getPath(connectivityProductState.data)

  useEffect(() => {
    if (connectivityProductState.data) {
      if (pathname === NOT_SUPPORTED) {
        navigate({
          pathname: `/${lang}/${NOT_SUPPORTED}`,
          search,
        })
      } else {
        navigate({
          pathname,
          search,
        })
      }
    }
  }, [connectivityProductState, lang, navigate, pathname, search])

  return pathname
}
