import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { determinePath } from 'utils/path'
import { Col } from 'react-bootstrap'
import { useConnectivityProductName } from 'hooks/suspension-hooks'
import { useMnoInfoContext } from '../../hooks/useMnoInfoContext'
import WrappedSpinner from '../wrapped-spinner/WrappedSpinner'

const LandingPage = (): JSX.Element => {
  const navigate = useNavigate()
  const search = useLocation().search
  const productInfo = useMnoInfoContext()
  const productName = useConnectivityProductName()

  useEffect(() => {
    if (productInfo?.data && productName) {
      navigate(determinePath(search, productName, productInfo.data.mno))
    }
  }, [productInfo, productName, navigate, search])

  return (
    <Col xs={12}>{!productInfo.data && <WrappedSpinner aria={{ 'aria-label': 'Loading mno country info' }} />}</Col>
  )
}

export default LandingPage
