import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'
import { Mno } from '../model/mno/Mno'
import { To } from 'react-router-dom'
import { mnoToPath, productNameToPath } from './mappings/path-mappings'
import { Search } from 'history'

export const SETUP = 'setup'
export const REGISTERED = 'registered'
export const NOT_REGISTERED = 'not-registered'
export const PENDING = 'pending'
export const NOT_FOUND = 'not-found'
export const UNAUTHORIZED = 'unauthorized'
export const ACTIVATION_ERROR = 'activation-error'
export const ERROR = 'error'
export const NOT_SUPPORTED = 'not-supported'
export const LITE = 'lite'
export const INACTIVE = 'inactive'
export const PREMIUM = 'premium'
export const WEB_COMPONENTS_TEST = 'web-components-test'

export const isOnErrorPage = (): boolean => {
  return !![ERROR, NOT_FOUND, NOT_SUPPORTED, UNAUTHORIZED].find((url) => {
    const pathnameParts = window.location.pathname.split('/')
    return pathnameParts.pop() === url
  })
}

export const determinePath = (search: Search, productName: ConnectivityProductName, mno?: Mno): To => {
  if (!productNameToPath.has(productName) || (mno && !mnoToPath.has(mno))) {
    return { pathname: NOT_FOUND, search }
  }

  const productNamePath = productNameToPath.get(productName)
  const mnoPath = mnoToPath.get(mno as Mno)

  return { pathname: `${productNamePath}/${mnoPath}`, search }
}
