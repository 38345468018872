/* eslint-disable */
import { createClient, CreateClientParams } from 'contentful'
import { FooterData } from 'model/footer/FooterData'
import { FooterEntrySkeleton } from 'model/footer/contentful/FooterEntrySkeleton'

const MAX_REFERENCE_DEPTH = 5;
const MAX_RESPONSE_LIMIT = 1000;

const getContentfulLocaleList = (locale: Intl.Locale): string[] => [...new Set([locale.baseName, locale.language, 'en'])]

const flattenContentfulEntries = (entries: any): any => {
  if (typeof entries !== 'object' || entries === null) {
    return entries
  }

  if (Array.isArray(entries)) {
    return entries.map((entry) => flattenContentfulEntries(entry))
  }

  if (!('fields' in entries)) {
    return entries
  }

  const { fields } = entries

  if (fields === null) {
    return fields
  }

  const flattenedFields: Record<string, any> = {}

  Object.keys(fields).forEach((key) => {
    flattenedFields[key] = flattenContentfulEntries(fields[key])
  })

  return flattenedFields
}

const filterContentForLocale = (locales: string[], entries: any[]): any => entries.sort(
  (a, b) => locales.indexOf(a.lang) - locales.indexOf(b.lang)
)

const createContentfulClient = (config: CreateClientParams) => {
  const client = createClient({
    space: config.space,
    accessToken: config.accessToken,
    environment: 'master',
    retryOnError: false,
  })

  return {
    getFooter(locale: string) {
      const locales = getContentfulLocaleList(new Intl.Locale(locale))
      return (
        client.withoutUnresolvableLinks
          .getEntries<FooterEntrySkeleton>({
            include: MAX_REFERENCE_DEPTH,
            content_type: 'footer',
            'fields.lang[in]': locales,
            limit: MAX_RESPONSE_LIMIT,
          })

          .then(({ items }) =>
            items.map((item) => flattenContentfulEntries(item))
          )
          .then((entries) => filterContentForLocale(locales, entries))
          .then((entries) => entries[0] ?? {})
          .catch((e) => {
            const err = JSON.parse(e.message)
            throw new Response(err.message, { status: err.status })
          })
      )
    },
  }
}

export const fetchFooter = async (
  locale: string,
  config: CreateClientParams
): Promise<FooterData> => await createContentfulClient(config).getFooter(locale)
