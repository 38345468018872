import { useMnoPortalUrl } from 'hooks/useMnoPortalUrl'
import { PHeading, PSpinner } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import React, { useMemo } from 'react'
import 'components/partner-portal-redirect/PartnerPortalRedirect.scss'

export const PartnerPortalRedirect = (): JSX.Element => {
  const mnoUrl = useMnoPortalUrl()
  if (mnoUrl.canRedirect && mnoUrl.url) {
    window.location.assign(mnoUrl.url)
  }

  const headingLabel = useMemo(() => {
    if (!mnoUrl.canRedirect) {
      return 'app.portalRedirect.cantRedirect.heading'
    }

    return 'app.portalRedirect.heading'
  }, [mnoUrl])

  return (
    <div className='partner-portal-redirect'>
      <PSpinner data-testid='spinner' size='large' aria={{ 'aria-label': 'Redirecting to MNO portal' }} />
      <PHeading size='large' data-testid='redirect-header'>
        <FormattedMessage id={headingLabel} />
      </PHeading>
    </div>
  )
}
