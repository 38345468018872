import { PLinkPure } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { useLang, useVinFromState } from 'hooks/hooks'
import { useMnoInfoContext } from 'hooks/useMnoInfoContext'
import { useMemo } from 'react'
import 'components/navigation/Navigation.scss'

interface NavigationProps {
  connectStoreContractUrl?: string
}

export const Navigation = ({ connectStoreContractUrl }: NavigationProps): JSX.Element => {
  const lang = useLang()
  const vin = useVinFromState()
  const productInfo = useMnoInfoContext()

  const goBackUrl = useMemo(
    () =>
      productInfo.data?.country && vin && lang
        ? `${connectStoreContractUrl}/${productInfo.data.country}/${lang}/overview?vin=${vin}`
        : connectStoreContractUrl,
    [connectStoreContractUrl, lang, productInfo.data?.country, vin]
  )

  return (
    <PLinkPure icon='arrow-head-left' size='small' className='back-to-vehicle'>
      <a href={goBackUrl} data-testid='back-to-vehicle'>
        <FormattedMessage id='app.navigation.backToVehicle' />
      </a>
    </PLinkPure>
  )
}
