import React from 'react'
import 'web-components/IitcPortalRedirectWidget'
import { useLang, useVinFromState } from 'hooks/hooks'
import { useAccessToken } from 'hooks/auth-hooks'

const WebComponentsTestPage = (): JSX.Element => {
  const token = useAccessToken().data
  const vin = useVinFromState()
  const languageTag = useLang()
  const language = languageTag?.substring(0, languageTag?.indexOf('-'))
  const country = languageTag?.substring(languageTag?.indexOf('-') + 1)
  const getEnvironment = (): string => {
    switch (window.location.hostname) {
      case 'dev-pcs-ui.porsche-preview.com':
        return 'dev'
      case 'pcs-ui.porsche-preview.com':
        return 'preprod'
      default:
        return 'local'
    }
  }

  return token && language && country ? (
    <iitc-portal-redirect-widget
      token={token}
      vin={vin}
      country={country}
      environment={getEnvironment()}
      language={language}
    />
  ) : (
    <></>
  )
}
export default WebComponentsTestPage
