import { FooterData } from 'model/footer/FooterData'
import { fetchFooter } from 'api/FooterApi'
import { useLang, useVinFromState } from 'hooks/hooks'
import { useEffect, useState } from 'react'
import { FutureResult, PENDING_RESULT, withData } from 'model/FutureResult'
import { FooterConfig } from 'model/footer/FooterConfig'

export const useFooterData = (footerConfig: FooterConfig): FutureResult<FooterData> => {
  const [footerData, setFooterData] = useState<FutureResult<FooterData>>(PENDING_RESULT)

  const lang = useLang()
  const vin = useVinFromState()

  const removeLastColumn = (footer: FooterData): FooterData => {
    footer.columns.pop()
    return footer
  }

  useEffect(() => {
    if (!lang) {
      return
    }

    fetchFooter(lang, {
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? '',
      space: process.env.REACT_APP_CONTENTFUL_SPACE ?? '',
    })
      .then((footer) => removeLastColumn(footer))
      .then((footer) =>
        JSON.parse(interpolatePlaceholder(JSON.stringify(footer), footerConfig, new Intl.Locale(lang), vin))
      )
      .then((footer) => setFooterData(withData(footer)))
  }, [footerConfig, lang, vin])

  return footerData
}

export const interpolatePlaceholder = (
  input: string,
  footerConfig: FooterConfig,
  locale: Intl.Locale,
  activeVin: string
): string => {
  const re = /({{ STORE }}|{{ PROFILE }}|{{ PORTAL_CORE }}|{{ ACTIVE_VIN }})/gi

  const mappedLocale = `${locale.language.toLowerCase()}_${locale.region!.toUpperCase()}`

  return input.replace(re, (match): string => {
    if (match === '{{ STORE }}') {
      return `${footerConfig.storeUrl}/${locale.region?.toLocaleLowerCase()}/${locale.language}`
    }
    if (match === '{{ PROFILE }}') {
      return `${footerConfig.profileUrl}/${locale.region}/${mappedLocale}`
    }
    if (match === '{{ PORTAL_CORE }}') {
      return `${footerConfig.portalCoreUrl}/${locale.region}/${mappedLocale}`
    }

    if (match === '{{ ACTIVE_VIN }}') {
      return activeVin
    }
    return ''
  })
}
