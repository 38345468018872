import { MnoPortalUrlWrapper } from '../model/partnerportalredirect/MnoPortalUrlWrapper'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppStore } from '../store/redux-hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { NOT_FOUND, NOT_SUPPORTED } from '../utils/path'
import { ConnectivityProductUIState } from '../model/uistate/ConnectivityProductUIState'
import { ReduxStore } from '../store/store'
import { concatMap, delay, from, interval, map, of } from 'rxjs'
import { fetchConnectivityProductUIState } from '../features/connectivity-product-ui-state/connectivity-product-ui-state-slice'
import { useLang, useVinFromState } from './hooks'

export const useMnoPortalUrl = (): MnoPortalUrlWrapper => {
  const [mnoUrl, setMnoUrl] = useState<MnoPortalUrlWrapper>({
    canRedirect: false,
  })

  const vin = useVinFromState()
  const lang = useLang()

  const dispatch = useAppDispatch()
  const store = useAppStore()

  const searchRef = useRef({ search: useLocation().search })
  const navigateRef = useRef({ navigate: useNavigate() })

  useEffect(() => {
    const navigate = navigateRef.current.navigate

    if (vin === '') {
      navigate({
        pathname: `/${lang}/${NOT_FOUND}`,
        search: searchRef.current.search,
      })
      return
    }

    const canRedirect = (state?: ConnectivityProductUIState): boolean =>
      state?.accountStatus === 'IN_ACTIVATION' || state?.accountStatus === 'ACTIVE'

    const refreshProductState = (store: ReduxStore): void => {
      const subscription = interval(0)
        .pipe(
          concatMap((count) => of(count).pipe(delay(5000))),
          concatMap(() => from(store.dispatch(fetchConnectivityProductUIState({ languageTag: lang })).unwrap())),
          map((connectivityState) => connectivityState.data)
        )
        .subscribe({
          next: (state) => {
            if (canRedirect(state)) {
              subscription.unsubscribe()
              setMnoUrl({
                url: state?.refs?.mnoPortalUrl as string,
                canRedirect: true,
              })
            }
          },
        })
    }

    const fetchUIState = async (): Promise<void> => {
      try {
        const connectivityState = await dispatch(fetchConnectivityProductUIState({ languageTag: lang })).unwrap()

        if (!canRedirect(connectivityState.data)) {
          setMnoUrl({ canRedirect: false })
          refreshProductState(store)
          return
        }
        setMnoUrl({
          url: connectivityState.data?.refs?.mnoPortalUrl as string,
          canRedirect: true,
        })
      } catch (error) {
        navigateRef.current.navigate({
          pathname: `/${lang}/${NOT_SUPPORTED}`,
          search: searchRef.current.search,
        })
      }
    }

    fetchUIState()
  }, [dispatch, lang, store, vin])

  return mnoUrl
}
