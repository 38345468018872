import { PHeading, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import 'components/error/unauthorized/Unauthorized.scss'
import { useHandleErrorRetry } from 'hooks/hooks'
import { Col, Container, Row } from 'react-bootstrap'

export const Unauthorized = (): JSX.Element => {
  useHandleErrorRetry()

  return (
    <Container fluid className='unauthorized gx-0' data-testid='unauthorized'>
      <Row>
        <Col
          xs={{ span: 11, offset: 1 }}
          md={{ span: 9, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 5, offset: 1 }}
        >
          <PHeading size='x-large' className='heading' data-testid='unauthorized-heading'>
            <FormattedMessage id='app.unauthorized.header' />
          </PHeading>
          <div>
            <img
              className='banner'
              srcSet={`
              /assets/unauthorized/unauthorized@3x.png 2400w,
              /assets/unauthorized/unauthorized@2x.png 1600w,
              /assets/unauthorized/unauthorized@1x.png 800w
              `}
              src={'/assets/unauthorized/unauthorized@3x.png'}
              alt='Porsche'
              data-testid='unauthorized-banner'
            />
          </div>

          <PText size='medium' data-testid='unauthorized-message'>
            <FormattedMessage id='app.unauthorized.message' />
          </PText>
        </Col>
      </Row>
    </Container>
  )
}
