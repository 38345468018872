import React, { useState } from 'react'
import { Subject } from 'rxjs'
import { useHandleRedirect, useInitApi, useLoginWithRedirect } from 'hooks/auth-hooks'
import WrappedSpinner from '../wrapped-spinner/WrappedSpinner'

export const OAuthApp = ({
  config: { apiUrl },
  httpError$,
  children,
}: {
  config: {
    apiUrl: string
  }
  httpError$: Subject<void>
  children: JSX.Element
}): JSX.Element => {
  const [apiInitialized, setApiInitialized] = useState(false)
  const [authInProgress, setAuthInProgress] = useState(false)

  useHandleRedirect()
  useLoginWithRedirect()
  useInitApi(apiUrl, httpError$, apiInitialized, setApiInitialized, authInProgress, setAuthInProgress)

  return <>{apiInitialized ? children : <WrappedSpinner aria={{ 'aria-label': 'Authorizing' }} />}</>
}
