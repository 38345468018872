import React from 'react'
import { PHeading, PPopover, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import 'components/eucapopover/EuCaPopover.scss'
import { VodafoneRefs } from 'api/EuApi'
import { RogersRefs } from 'api/CaApi'

const EuCaPopover = ({
  refs,
  translationPrefix,
}: {
  refs?: VodafoneRefs | RogersRefs
  translationPrefix: string
}): JSX.Element => {
  return (
    <PPopover className='popover-icon'>
      <div className='popover-flex'>
        <PHeading size='medium'>
          <FormattedMessage id={`${translationPrefix}.title`} />
        </PHeading>

        <PText>
          <FormattedMessage id={`${translationPrefix}.description.paragraph1`} />
          <a href={refs?.connectPackageOverview} target='_blank' rel='noreferrer'>
            {' '}
            <FormattedMessage id='app.popover.description.here' />
          </a>
        </PText>

        <PText>
          <FormattedMessage id={`${translationPrefix}.description.paragraph2`} />
        </PText>

        <PText>
          <FormattedMessage id={`${translationPrefix}.description.paragraph3`} />
        </PText>

        <PText color='contrast-medium' size='x-small'>
          <FormattedMessage id={`${translationPrefix}.description.notice`} />
        </PText>
      </div>
    </PPopover>
  )
}

export default EuCaPopover
