import React, { Fragment } from 'react'
import { useLang } from 'hooks/hooks'
import { PDivider } from '@porsche-design-system/components-react'

const Header = ({ navigationEnv }: { navigationEnv: string }): JSX.Element => {
  const lang = useLang()?.replace('_', '-')

  return (
    <div data-testid='header'>
      <Fragment>
        <phn-header locale={lang} env={navigationEnv} mode='transparent' />
        <PDivider color='contrast-low' />
      </Fragment>
    </div>
  )
}

export default Header
