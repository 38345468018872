import { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { initApi, isLocalOrTest } from 'api/Api'
import { Subject } from 'rxjs'
import { FutureResult, PENDING_RESULT, withData } from 'model/FutureResult'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSessionStorage } from 'usehooks-ts'
import { FALSE, IS_LOGGING_IN, TRUE } from 'model/StorageConstants'

const REDIRECT_PATHNAME = 'REDIRECT_PATHNAME'
const REDIRECT_SEARCH = 'REDIRECT_SEARCH'

const getAuthQueryParams = (searchParams: URLSearchParams): boolean =>
  !!searchParams.get('code') && !!searchParams.get('state')

export const useLoginWithRedirect = (): void => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const [searchParams] = useSearchParams()
  const searchParamsRef = useRef({ searchParams })
  const [, setIsLoggingIn] = useSessionStorage(IS_LOGGING_IN, FALSE)

  useEffect(() => {
    if (
      !isLocalOrTest() &&
      !isAuthenticated &&
      !isLoading &&
      !getAuthQueryParams(searchParamsRef.current.searchParams)
    ) {
      sessionStorage.setItem(REDIRECT_PATHNAME, window.location.pathname)
      sessionStorage.setItem(REDIRECT_SEARCH, window.location.search)
      setIsLoggingIn(TRUE)
      loginWithRedirect()
    }
  }, [setIsLoggingIn, isAuthenticated, isLoading, loginWithRedirect])
}

export const useInitApi = (
  apiUrl: string,
  httpError$: Subject<void>,
  apiInitialized: boolean,
  setApiInitialized: (value: ((prevState: boolean) => boolean) | boolean) => void,
  authInProgress: boolean,
  setAuthInProgress: (value: ((prevState: boolean) => boolean) | boolean) => void
): void => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const [searchParams] = useSearchParams()
  const searchParamsRef = useRef({ searchParams })

  useEffect(() => {
    if (isLocalOrTest()) {
      initApi(apiUrl, httpError$)
      setApiInitialized(true)
      return
    }

    if (
      (!isAuthenticated && !getAuthQueryParams(searchParamsRef.current.searchParams)) ||
      isLoading ||
      authInProgress ||
      apiInitialized
    ) {
      return
    }

    /* @auth0/auth0-react is using browser-tabs-lock - if it's called directly after app init it can cause long synchronization.
     * As library doesn't expose any methods to verify if state is already synced we have to use delay as a workaround */
    const tokenDelay = getAuthQueryParams(searchParamsRef.current.searchParams) ? 250 : 0

    setTimeout(() => {
      setAuthInProgress(true)
      getAccessTokenSilently().then(() => {
        initApi(apiUrl, httpError$, getAccessTokenSilently)
        setApiInitialized(true)
        setAuthInProgress(false)
      })
    }, tokenDelay)
  }, [
    apiInitialized,
    apiUrl,
    authInProgress,
    getAccessTokenSilently,
    httpError$,
    isAuthenticated,
    isLoading,
    setApiInitialized,
    setAuthInProgress,
  ])
}

export const useAccessToken = (): FutureResult<string> => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<FutureResult<string>>(PENDING_RESULT)

  useEffect(() => {
    if (!isLocalOrTest()) {
      getAccessTokenSilently().then((token) => setAccessToken(withData(token)))
    }
  }, [getAccessTokenSilently])

  return accessToken
}

export const useHandleRedirect = (): void => {
  const navigateRef = useRef({ navigate: useNavigate() })
  const { isAuthenticated } = useAuth0()
  const [, setIsLoggingIn] = useSessionStorage(IS_LOGGING_IN, FALSE)

  useEffect(() => {
    if (isLocalOrTest()) {
      return
    }

    const pathname = sessionStorage.getItem(REDIRECT_PATHNAME) || ''
    const search = sessionStorage.getItem(REDIRECT_SEARCH) || ''

    if (isAuthenticated && (pathname || search)) {
      sessionStorage.removeItem(REDIRECT_PATHNAME)
      sessionStorage.removeItem(REDIRECT_SEARCH)
      setIsLoggingIn(FALSE)
      navigateRef.current.navigate({
        pathname,
        search,
      })
    }
  }, [setIsLoggingIn, isAuthenticated])
}
