import { PHeading, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import 'components/error/technical-error/TechnicalError.scss'
import { useHandleErrorRetry } from 'hooks/hooks'
import { Col, Container, Row } from 'react-bootstrap'

export const TechnicalError = (): JSX.Element => {
  useHandleErrorRetry()

  return (
    <Container className='technical-error gx-0' data-testid='technical-error' fluid>
      <Row>
        <Col
          xs={{ span: 11, offset: 1 }}
          md={{ span: 9, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 5, offset: 1 }}
        >
          <PHeading size='x-large' className='heading' data-testid='technical-error-heading'>
            <FormattedMessage id='app.technicalError.header' />
          </PHeading>

          <div>
            <img
              className='banner'
              srcSet={`
              /assets/technical-error/technical_error@3x.png 2350w,
              /assets/technical-error/technical_error@2x.png 1566w,
              /assets/technical-error/technical_error@1x.png 783w
              `}
              src={'/assets/technical-error/technical_error@3x.png'}
              alt='Porsche expo'
              data-testid='technical-error-banner'
            />
          </div>

          <PText size='medium' data-testid='technical-error-message'>
            <FormattedMessage id='app.technicalError.message' />
          </PText>
        </Col>
      </Row>
    </Container>
  )
}
