import React from 'react'
import { PHeading, PPopover, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import 'components/us-rel4/popover/UsPopover.scss'
import { SoftbankRefs } from 'api/JpApi'

const JpPopover = ({ refs }: { refs?: SoftbankRefs }): JSX.Element => {
  return (
    <PPopover className='popover-icon'>
      <div className='popover-flex'>
        <PHeading size='medium'>
          <FormattedMessage id='app.jp.popover.title' />
        </PHeading>
        <PText>
          <FormattedMessage
            id='app.jp.popover.description1'
            values={{
              here: (
                <a href={refs?.productDetailsUrl} target='_blank' rel='noreferrer'>
                  <FormattedMessage id='app.popover.description.here' />
                </a>
              ),
            }}
          />
        </PText>
        <PText>
          <FormattedMessage id='app.jp.popover.description2' />
        </PText>

        <div>
          <PText size='x-small'>
            <FormattedMessage id='app.jp.popover.notice' />
          </PText>
        </div>
      </div>
    </PPopover>
  )
}

export default JpPopover
