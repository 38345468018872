export type Value<T> = {
  readonly pending: false
  readonly error: false
  readonly data: T
}

export type Loading = {
  readonly pending: true
  readonly error: false
  readonly data?: undefined
}

export type Error = {
  readonly error: true
  readonly pending: false
  readonly data?: undefined
}
export type FutureResult<T> = Value<T> | Loading | Error

export const withData = <T>(data: T): FutureResult<T> => {
  return { data, pending: false, error: false }
}

export const PENDING_RESULT: Loading = {
  error: false,
  pending: true,
}

export const ERROR_RESULT: Error = {
  error: true,
  pending: false,
}
