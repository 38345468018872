import 'components/footer/Footer.scss'
import { FooterLinks } from 'components/footer/footer-links/FooterLinks'
import { PDivider } from '@porsche-design-system/components-react'
import React from 'react'
import { useIsMobileMode } from 'hooks/hooks'
import { FooterColumn } from 'model/footer/FooterColumn'
import { FooterBadges } from 'components/footer/footer-badges/FooterBadges'
import { useFooterData } from 'hooks/footer-hooks'
import { FooterConfig } from 'model/footer/FooterConfig'
import { FooterLegal } from 'components/footer/footer-legal/FooterLegal'

export const Footer = ({ footerConfig }: { footerConfig: FooterConfig }): JSX.Element => {
  const isMobileMode = useIsMobileMode()

  const footerData = useFooterData(footerConfig)

  return (
    <div data-testid='footer' className='footer'>
      {footerData.data && (
        <div className='footer-wrapper'>
          <div className='footer-groups'>
            {footerData.data.columns.map((column: FooterColumn) => (
              <div key={column.name}>
                {column.links && <FooterLinks title={column.name} links={column.links} />}
                {column.badges && <FooterBadges title={column.name} badges={column.badges} />}
              </div>
            ))}
          </div>

          {!isMobileMode && <PDivider color='contrast-high' className='footer-divider' />}

          {footerData.data.disclaimer && <FooterLegal disclaimer={footerData.data.disclaimer} />}
        </div>
      )}
    </div>
  )
}
