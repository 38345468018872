import { PHeading, PSwitch, SwitchUpdateEvent } from '@porsche-design-system/components-react'
import {
  ConnectivitySuspensionState,
  isMobileDataEnabled,
  isMobileDataInProgress,
} from 'model/state/ConnectivitySuspensionState'
import { FormattedMessage } from 'react-intl'
import React, { useEffect } from 'react'
import { useLang, useVinFromState } from 'hooks/hooks'
import { useResumeConnectivity, useSuspendConnectivity } from 'hooks/suspension-hooks'
import { useAppStore } from 'store/redux-hooks'
import 'components/eu-rel4/suspension-toggle/SuspensionToggle.scss'
import { refreshProductState } from 'hooks/connectivity-product-ui-state-hooks'

export const SuspensionToggle = ({
  toggleClicked,
  suspensionState,
  setToggleClicked,
}: {
  toggleClicked: boolean
  suspensionState: ConnectivitySuspensionState
  setToggleClicked: (toggleClicked: boolean) => void
}): JSX.Element => {
  useEffect(() => {
    setToggleClicked(false)
  }, [setToggleClicked, suspensionState])

  const vin = useVinFromState()

  const resumeConnectivity = useResumeConnectivity()
  const suspendConnectivity = useSuspendConnectivity()

  const store = useAppStore()
  const lang = useLang()
  const changeSuspensionState = (event: CustomEvent<SwitchUpdateEvent>): void => {
    setToggleClicked(true)
    const action = event.detail.checked ? resumeConnectivity(vin) : suspendConnectivity(vin)

    action.then(() => refreshProductState(store, lang))
  }

  return (
    <>
      {suspensionState && (
        <PSwitch
          alignLabel='left'
          data-testid='suspension-toggle'
          checked={isMobileDataEnabled(suspensionState)}
          loading={toggleClicked || isMobileDataInProgress(suspensionState)}
          onUpdate={changeSuspensionState}
        >
          <PHeading size='medium' className='suspension-toggle-text'>
            <FormattedMessage id='app.suspension.mobileData' />
          </PHeading>
        </PSwitch>
      )}
    </>
  )
}
