import { getTranslationForLanguage, useLanguage } from 'utils/language'
import { IntlProvider } from 'react-intl'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Mno } from 'model/mno/Mno'
import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'
import { NavigationWrapper } from 'components/navigation-wrapper/NavigationWrapper'
import { PartnerPortalRedirect } from 'components/partner-portal-redirect/PartnerPortalRedirect'
import { App } from 'components/app/App'
import { ErrorBanner } from 'components/error/error-banner/ErrorBanner'
import { Observable } from 'rxjs'
import { FooterConfig } from 'model/footer/FooterConfig'
import { WEB_COMPONENTS_TEST } from 'utils/path'
import WebComponentsTestPage from 'components/web-components-test-page/WebComponentsTestPage'
import WrappedSpinner from '../wrapped-spinner/WrappedSpinner'

interface IntlAppProps {
  config: {
    navigationEnv: string
    connectStoreContractUrl: string
    footer: FooterConfig
  }
  httpError$: Observable<void>
}

export const IntlApp = ({
  config: { navigationEnv, connectStoreContractUrl, footer },
  httpError$,
}: IntlAppProps): JSX.Element => {
  const language = useLanguage()
  /* eslint-disable-next-line */
  const [messages, setMessages] = useState<any>()

  useEffect(() => {
    getTranslationForLanguage(language)
      .then((translations) => {
        setMessages(translations)
      })
      .catch((error) => console.log(error))
  }, [language])

  const updateTranslations = (productName: ConnectivityProductName, mno: Mno): void => {}

  return language ? (
    <IntlProvider
      data-testid='intl-provider'
      messages={messages}
      locale={language}
      onError={() => undefined}
      defaultRichTextElements={{
        b: (chunks) => <b>{chunks}</b>,
        p: (chunks) => <p>{chunks}</p>,
      }}
    >
      <ErrorBanner httpError$={httpError$} />

      <Routes>
        <Route
          path='/:lang/partner-portal-redirect'
          element={
            <NavigationWrapper
              navigationEnv={navigationEnv}
              connectStoreContractUrl={connectStoreContractUrl}
              footerConfig={footer}
            >
              <PartnerPortalRedirect />
            </NavigationWrapper>
          }
        />

        <Route path={`/:lang/${WEB_COMPONENTS_TEST}`} element={<WebComponentsTestPage />} />

        <Route
          path='*'
          element={
            <App
              navigationEnv={navigationEnv}
              connectStoreContractUrl={connectStoreContractUrl}
              updateTranslations={updateTranslations}
              footerConfig={footer}
            />
          }
        />
      </Routes>
    </IntlProvider>
  ) : (
    <WrappedSpinner aria={{ 'aria-label': 'Loading language' }} />
  )
}
