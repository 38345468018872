export const defaultRegions = new Map<string, string>([
  ['en', 'en-GB'],
  ['de', 'de-DE'],
  ['nl', 'nl-NL'],
  ['be', 'nl-BE'],
  ['ch', 'de-CH'],
  ['at', 'de-AT'],
  ['bg', 'en-BG'],
  ['cy', 'en-CY'],
  ['fr', 'fr-FR'],
  ['hr', 'hr-HR'],
  ['ru', 'ru-RU'],
  ['cs', 'cs-CZ'],
  ['cz', 'cs-CZ'],
  ['gr', 'en-GR'],
  ['is', 'en-IS'],
  ['ie', 'en-IE'],
  ['da', 'da-DK'],
  ['et', 'et-EE'],
  ['fi', 'fi-FI'],
  ['hu', 'hu-HU'],
  ['it', 'it-IT'],
  ['lv', 'lv-LV'],
  ['lt', 'lt-LT'],
  ['mt', 'en-MT'],
  ['mc', 'fr-MC'],
  ['nb', 'no-NO'],
  ['no', 'no-NO'],
  ['pl', 'pl-PL'],
  ['pt', 'pt-PT'],
  ['ro', 'ro-RO'],
  ['sk', 'sk-SK'],
  ['sl', 'sl-SI'],
  ['es', 'es-ES'],
  ['sv', 'sv-SE'],
  ['ja', 'ja-JP'],
])
export const supportedLanguageTags = [
  'en-AT',
  'de-AT',
  'en-BE',
  'nl-BE',
  'fr-BE',
  'en-BG',
  'en-HR',
  'hr-HR',
  'en-CY',
  'ru-CY',
  'en-CZ',
  'cs-CZ',
  'en-DK',
  'da-DK',
  'en-EE',
  'et-EE',
  'ru-EE',
  'en-FI',
  'fi-FI',
  'en-FR',
  'fr-FR',
  'en-DE',
  'de-DE',
  'en-GR',
  'en-HU',
  'hu-HU',
  'en-IS',
  'en-IE',
  'en-IT',
  'it-IT',
  'en-LV',
  'lv-LV',
  'ru-LV',
  'en-LI',
  'de-LI',
  'fr-LI',
  'it-LI',
  'en-LT',
  'lt-LT',
  'ru-LT',
  'en-LU',
  'fr-LU',
  'de-LU',
  'en-MT',
  'en-MC',
  'fr-MC',
  'en-NL',
  'nl-NL',
  'en-NO',
  'nb-NO',
  'no-NO',
  'en-PL',
  'pl-PL',
  'en-PT',
  'pt-PT',
  'en-RO',
  'ro-RO',
  'en-SK',
  'sk-SK',
  'cs-SK',
  'en-SI',
  'sl-SI',
  'en-ES',
  'es-ES',
  'es-PR',
  'en-SE',
  'sv-SE',
  'en-CH',
  'de-CH',
  'fr-CH',
  'it-CH',
  'en-GB',
  'en-US',
  'en-PR',
  'en-CA',
  'fr-CA',
  'en-JP',
  'ja-JP',
] as const

export const supportedLanguages = Array.from(defaultRegions.keys())

export type SupportedLanguage = (typeof supportedLanguageTags)[number]
