import { components } from 'api/generated-schema'

export type ConnectivitySuspensionState = components['schemas']['ActiveSuspendedStateDTO']['suspensionStatus']

export const isMobileDataDisabled = (state?: ConnectivitySuspensionState): boolean => {
  return ['SUSPENDED', 'IN_ACTIVATION'].includes(state ?? '')
}

export const isMobileDataEnabled = (state?: ConnectivitySuspensionState): boolean => {
  return ['ACTIVE', 'IN_SUSPENSION'].includes(state ?? '')
}

export const isMobileDataInProgress = (state?: ConnectivitySuspensionState): boolean => {
  return ['IN_ACTIVATION', 'IN_SUSPENSION'].includes(state ?? '')
}

export const isMobileDataInActivation = (state?: ConnectivitySuspensionState): boolean => {
  return 'IN_ACTIVATION' === state
}

export const isMobileDataSuspended = (state?: ConnectivitySuspensionState): boolean => {
  return 'SUSPENDED' === state
}

export const isMobileDataActive = (state?: ConnectivitySuspensionState): boolean => {
  return 'ACTIVE' === state
}
