import React, { useEffect, useMemo, useRef } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import LandingPage from 'components/landing-page/LandingPage'
import { AppContext } from 'context/AppContext'
import { useLang } from 'hooks/hooks'
import { NavigationWrapper } from 'components/navigation-wrapper/NavigationWrapper'
import { Mno } from 'model/mno/Mno'
import MnoContent from 'components/mno-content/MnoContent'
import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'
import { ERROR, NOT_FOUND, NOT_SUPPORTED, UNAUTHORIZED } from 'utils/path'
import { NotFound } from 'components/error/not-found/NotFound'
import { TechnicalError } from 'components/error/technical-error/TechnicalError'
import { NotSupported } from 'components/error/not-supported/NotSupported'
import { Unauthorized } from 'components/error/unauthorized/Unauthorized'
import { FooterConfig } from 'model/footer/FooterConfig'
import { useConnectivityProductUIState } from 'hooks/connectivity-product-ui-state-hooks'
import { PENDING_RESULT, withData } from 'model/FutureResult'

interface AppProps {
  updateTranslations: (productName: ConnectivityProductName, mno: Mno) => void
  navigationEnv: string
  footerConfig: FooterConfig
  connectStoreContractUrl?: string
}

export const App = ({
  updateTranslations,
  navigationEnv,
  footerConfig,
  connectStoreContractUrl,
}: AppProps): JSX.Element => {
  const lang = useLang()
  const search = useLocation().search

  const connectivityProductUIState = useConnectivityProductUIState()
  const updateTranslationsRef = useRef({ updateTranslations })

  useEffect(() => {
    const productName = connectivityProductUIState.data?.connectivityProduct
    const mno = connectivityProductUIState.data?.mnoInformation?.mno
    if (productName && mno) {
      updateTranslationsRef.current.updateTranslations(productName as ConnectivityProductName, mno)
    }
  }, [connectivityProductUIState.data?.connectivityProduct, connectivityProductUIState.data?.mnoInformation?.mno])

  const mnoCountryMemo = useMemo(
    () => ({
      mnoCountry: connectivityProductUIState.data?.mnoInformation
        ? withData(connectivityProductUIState.data?.mnoInformation)
        : PENDING_RESULT,
    }),
    [connectivityProductUIState.data?.mnoInformation]
  )

  return (
    <AppContext.Provider value={mnoCountryMemo}>
      <NavigationWrapper
        navigationEnv={navigationEnv}
        connectStoreContractUrl={connectStoreContractUrl}
        footerConfig={footerConfig}
      >
        <Routes>
          <Route path='/:lang' element={<LandingPage />} />
          <Route path='/:lang/*' element={<MnoContent />} />

          <Route path={`/:lang/${NOT_FOUND}`} element={<NotFound />} />
          <Route path={`/:lang/${ERROR}`} element={<TechnicalError />} />
          <Route path={`/:lang/${NOT_SUPPORTED}`} element={<NotSupported />} />
          <Route path={`/:lang/${UNAUTHORIZED}`} element={<Unauthorized />} />

          <Route path='*' element={<Navigate to={{ pathname: `/${lang}/${NOT_FOUND}`, search }} replace />} />
        </Routes>
      </NavigationWrapper>
    </AppContext.Provider>
  )
}
