import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ERROR_RESULT, FutureResult, PENDING_RESULT, withData } from 'model/FutureResult'
import { ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'
import { RootState } from 'store/store'
import { getConnectivityProductUIState } from 'api/CommonApi'

interface ConnectivityProductState {
  value: FutureResult<ConnectivityProductUIState>
}

const initialState: ConnectivityProductState = {
  value: PENDING_RESULT,
}

export const fetchConnectivityProductUIState = createAsyncThunk<
  FutureResult<ConnectivityProductUIState>,
  { languageTag?: string },
  { state: RootState }
>('connectivityProductUIState/fetch', async ({ languageTag }, { getState }) => {
  const vin = getState().vin.value
  const stateUi = await getConnectivityProductUIState(vin, languageTag)
  return withData(stateUi)
})

export const connectivityProductUiStateSlice = createSlice({
  name: 'connectivityProductUIState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnectivityProductUIState.fulfilled, (state, action) => {
        state.value = action.payload
      })
      .addCase(fetchConnectivityProductUIState.rejected, (state) => {
        state.value = ERROR_RESULT
      })
  },
})

export default connectivityProductUiStateSlice.reducer
