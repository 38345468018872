import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'
import { Mno } from 'model/mno/Mno'

export const productNameToPath = new Map<ConnectivityProductName, string>([
  [ConnectivityProductName.EU_RELEASE4_CONNECTIVITY, 'vodafone'],
  [ConnectivityProductName.CA_RELEASE4_CONNECTIVITY, 'rogers'],
  [ConnectivityProductName.JP_RELEASE4_CONNECTIVITY, 'softbank'],
  [ConnectivityProductName.US_UNLIMITED_CONNECTIVITY, 'verizon'],
])

export const mnoToPath = new Map<Mno, string>([
  ['VODAFONE', 'vodafone'],
  ['A1AT', 'a1at'],
  ['CYTA', 'cyta'],
  ['ELISA', 'elisa'],
  ['ELISA_EE', 'elisaee'],
  ['TDC', 'tdc'],
  ['SFR', 'sfr'],
  ['POLKOMTEL', 'polkomtel'],
  ['PROXIMUS', 'proximus'],
  ['ROGERS', 'rogers'],
  ['TANGO', 'tango'],
  ['TELENOR', 'telenor'],
  ['VERIZON', 'verizon'],
  ['A1BG', 'a1bg'],
  ['A1HR', 'a1hr'],
  ['A1SI', 'a1si'],
  ['BITE_LATVIA', 'bitelatvia'],
  ['BITE_LITHUANIA', 'bitelithuania'],
  ['EPIC', 'epic'],
  ['SWISSCOM', 'swisscom'],
  ['SOFTBANK', 'softbank'],
])
