import 'components/footer/footer-links/FooterLinks.scss'
import { PAccordion, PHeading, PLinkPure } from '@porsche-design-system/components-react'
import React, { useState } from 'react'
import { useIsMobileMode } from 'hooks/hooks'
import { Container, Row } from 'react-bootstrap'
import { LinkModel } from 'model/footer/LinkModel'

const filterLinks = (link: LinkModel): boolean => link.clickEventName !== 'myporsche_services'

export const FooterLinks = ({ title, links }: { title: string; links: LinkModel[] }): JSX.Element => {
  const isMobileMode = useIsMobileMode()

  return (
    <div>
      {isMobileMode ? (
        <MobileFooterGroup title={title} links={links} />
      ) : (
        <>
          <PHeading size='large' className='footer-group-header' color='inherit'>
            {title}
          </PHeading>

          <Container fluid>
            <Row>
              {links.filter(filterLinks).map((link) => (
                <div key={link.clickEventName} className='footer-link'>
                  <Ref link={link} />
                </div>
              ))}
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

const MobileFooterGroup = ({ title, links }: { title: string; links: LinkModel[] }): JSX.Element => {
  const [isAccordionOpen, setAccordionOpen] = useState(false)

  return (
    <PAccordion open={isAccordionOpen} onAccordionChange={(e) => setAccordionOpen(e.detail.open)} theme='dark'>
      <PHeading slot='heading' size='x-large' color='inherit' className='mobile-footer-group-header'>
        {title}
      </PHeading>

      {links.filter(filterLinks).map((link) => (
        <div key={link.clickEventName}>
          <Ref link={link} />
        </div>
      ))}
    </PAccordion>
  )
}

const Ref = ({ link }: { link: LinkModel }): JSX.Element => {
  return (
    <PLinkPure href={link.url} icon='none' theme='dark' className='ref' data-testid='footer-link'>
      {link.name}
    </PLinkPure>
  )
}
