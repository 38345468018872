import axios, { AxiosInstance } from 'axios'
import { Subject } from 'rxjs'

let API: AxiosInstance

export const isLocalOrTest = (): boolean =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'

export const initApi = (apiUrl: string, httpError$?: Subject<void>, getAccessToken?: () => Promise<string>): void => {
  API = !isLocalOrTest()
    ? axios.create({
        baseURL: apiUrl,
        withCredentials: true,
      })
    : axios.create()
  if (getAccessToken) {
    setupRequestInterceptors(getAccessToken)
  }

  if (httpError$) {
    setupResponseInterceptors(httpError$)
  }
}

const setupRequestInterceptors = (getAccessToken: () => Promise<string>): void => {
  API.interceptors.request.use(async (config) => {
    if (config?.headers && getAccessToken) {
      const accessToken = await getAccessToken()
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  })
}

const setupResponseInterceptors = (httpError$: Subject<void>): void => {
  API.interceptors.response.use(
    (response) => response,
    (rejected) => {
      console.error(rejected.response)
      httpError$.next()

      return Promise.reject(rejected)
    }
  )
}

export const getApi: () => AxiosInstance = () => API
