import { configureStore } from '@reduxjs/toolkit'
import vinReducer from 'features/vin/vin-slice'
import connectivityUIStateReducer from 'features/connectivity-product-ui-state/connectivity-product-ui-state-slice'

export const store = configureStore({
  reducer: {
    vin: vinReducer,
    connectivityProductUIState: connectivityUIStateReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type ReduxStore = typeof store
