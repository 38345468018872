import { Navigate, useLocation } from 'react-router-dom'
import { useLang } from 'hooks/hooks'
import WrappedSpinner from '../wrapped-spinner/WrappedSpinner'

export const GuardedRoute = ({
  authorized,
  loading = true,
  redirectTo = 'error',
  children,
}: {
  authorized: boolean
  loading?: boolean
  redirectTo?: string
  children: JSX.Element
}): JSX.Element => {
  const lang = useLang()
  const search = useLocation().search

  if (loading) {
    return <WrappedSpinner />
  }

  if (!authorized) {
    return <Navigate to={{ pathname: `/${lang}/${redirectTo}`, search }} replace />
  }

  return children
}
