import { getApi } from 'api/Api'
import { components } from 'api/generated-schema'

export type ConnectivitySuspensionOperation = components['schemas']['SuspendConnectivityResultDTO']

export type VodafoneRefs = components['schemas']['EuMnoRefDTO']
export const suspendEuConnectivity = (vin: string): Promise<ConnectivitySuspensionOperation> =>
  getApi()
    ?.post<ConnectivitySuspensionOperation>(`/v1/sim/eu-release4-connectivity/suspension/${vin}`)
    .then((res) => res.data)

export const resumeEuConnectivity = (vin: string): Promise<ConnectivitySuspensionOperation> =>
  getApi()
    ?.delete<ConnectivitySuspensionOperation>(`/v1/sim/eu-release4-connectivity/suspension/${vin}`)
    .then((res) => res.data)

export const createAccount = (vin: string): Promise<void> =>
  getApi()?.post(`/v1/sim/eu-release4-connectivity/account/create-request`, { vin })
