import 'components/footer/footer-legal/FooterLegal.scss'
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { RichTextDocument } from 'model/footer/RichTextDocument'

export const FooterLegal = ({ disclaimer }: { disclaimer: RichTextDocument }): JSX.Element => {
  return (
    <div className='legal' data-testid='footer-legal'>
      {documentToReactComponents(disclaimer)}
    </div>
  )
}
