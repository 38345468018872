import { MnoInfo } from '../model/uistate/ConnectivityProductUIState'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { FutureResult, PENDING_RESULT, withData } from '../model/FutureResult'

export const useMnoInfoContext = (): FutureResult<MnoInfo> => {
  const [mnoCountry, setMnoCountry] = useState<FutureResult<MnoInfo>>(PENDING_RESULT)
  const appContext = useContext(AppContext)

  useEffect(() => {
    if (appContext?.mnoCountry?.data) {
      setMnoCountry(withData(appContext.mnoCountry.data))
    }
  }, [appContext.mnoCountry.data])

  return mnoCountry
}
