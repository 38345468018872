import React from 'react'
import 'components/CommonStyles.scss'
import { ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'
import { FutureResult } from 'model/FutureResult'
import { usePath } from 'hooks/hooks'
import WrappedSpinner from '../wrapped-spinner/WrappedSpinner'

const Rel4LandingPage = ({
  connectivityProductState,
}: {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}): JSX.Element => {
  usePath(connectivityProductState)
  return (
    <>{connectivityProductState.pending && <WrappedSpinner aria={{ 'aria-label': 'Loading connectivity state' }} />}</>
  )
}

export default Rel4LandingPage
