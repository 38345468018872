import { useMemo } from 'react'
import { ConnectivitySuspensionOperation, resumeEuConnectivity, suspendEuConnectivity } from 'api/EuApi'
import { useAppSelector } from 'store/redux-hooks'
import { ConnectivityProductName } from 'model/productName/ConnectivityProductName'

export const useConnectivityProductName = (): ConnectivityProductName | undefined => {
  return useAppSelector((state) => {
    return state.connectivityProductUIState.value.data?.connectivityProduct as ConnectivityProductName
  })
}

export const useResumeConnectivity = (): ((vin: string) => Promise<ConnectivitySuspensionOperation>) => {
  const connectivityProductName = useConnectivityProductName()

  return useMemo(() => getResumeConnectivity(connectivityProductName), [connectivityProductName])
}

export const useSuspendConnectivity = (): ((vin: string) => Promise<ConnectivitySuspensionOperation>) => {
  const connectivityProductName = useConnectivityProductName()

  return useMemo(() => getSuspendConnectivity(connectivityProductName), [connectivityProductName])
}

const getResumeConnectivity = (
  productName?: ConnectivityProductName
): ((vin: string) => Promise<ConnectivitySuspensionOperation>) => {
  if (productName === ConnectivityProductName.EU_RELEASE4_CONNECTIVITY) {
    return resumeEuConnectivity
  } else {
    return () => Promise.resolve({ message: undefined, additionalInfo: undefined })
  }
}

const getSuspendConnectivity = (
  productName?: ConnectivityProductName
): ((vin: string) => Promise<ConnectivitySuspensionOperation>) => {
  if (productName === ConnectivityProductName.EU_RELEASE4_CONNECTIVITY) {
    return suspendEuConnectivity
  } else {
    return () => Promise.resolve({ message: undefined, additionalInfo: undefined })
  }
}
