import { PHeading, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import 'components/error/not-found/NotFound.scss'
import { useHandleErrorRetry } from 'hooks/hooks'
import { Col, Container, Row } from 'react-bootstrap'

export const NotFound = (): JSX.Element => {
  useHandleErrorRetry()

  return (
    <Container className='not-found gx-0' data-testid='not-found' fluid>
      <Row>
        <Col
          xs={{ span: 11, offset: 1 }}
          md={{ span: 9, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 5, offset: 1 }}
        >
          <PHeading size='x-large' className='heading' data-testid='not-found-heading'>
            <FormattedMessage id='app.notFound.header' />
          </PHeading>

          <div>
            <img
              className='banner'
              srcSet={`
              /assets/not-found/not_found@3x.png 2400w,
              /assets/not-found/not_found@2x.png 1600w,
              /assets/not-found/not_found@1x.png 800w
              `}
              src={'/assets/not-found/not_found@3x.png'}
              alt='Porsche'
              data-testid='not-found-banner'
            />
          </div>

          <PText size='medium' data-testid='not-found-message'>
            <FormattedMessage id='app.notFound.message' />
          </PText>
        </Col>
      </Row>
    </Container>
  )
}
