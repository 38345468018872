import 'components/top-bar/TopBar.scss'
import { PHeading, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { useMnoInfoContext } from 'hooks/useMnoInfoContext'
import { VodafoneRefs } from 'api/EuApi'
import { RogersRefs } from 'api/CaApi'
import { Col, Container, Row } from 'react-bootstrap'
import React from 'react'

export const TopBar = ({
  refs,
  displayProviderInfo = true,
  popover,
  registrationState,
}: {
  refs?: VodafoneRefs | RogersRefs
  displayProviderInfo?: boolean
  popover?: JSX.Element
  registrationState?: JSX.Element
}): JSX.Element => {
  const { data: mnoInfo } = useMnoInfoContext()

  return (
    <Container fluid data-testid='top-bar' className='content'>
      <Row>
        <Col sm={12} md={8}>
          <PHeading size='x-large' data-testid='connectivity-header'>
            <FormattedMessage id='app.topBar.header' />
            {popover}
          </PHeading>
          {displayProviderInfo && (
            <PText className='top-bar-description' role='complementary' data-testid='provider-text' size='x-small'>
              <FormattedMessage id='app.topBar.provider' />{' '}
              <a href={refs?.contactDetails?.imprint} target='_blank' rel='noreferrer'>
                {mnoInfo?.displayName}
              </a>
            </PText>
          )}
        </Col>

        {displayProviderInfo && (
          <Col className='mno-info' sm={10} md={4}>
            <div className='mno-logo-container'>
              {mnoInfo?.mno && (
                <div className='mno-logo-wrapper'>
                  <a data-testid='mno-logo-href' href={refs?.contactDetails?.imprint} target='_blank' rel='noreferrer'>
                    <img
                      data-testid='mno-logo'
                      className='mno-logo'
                      src={`/assets/logo/${mnoInfo.mno.toLowerCase()}/3x.png`}
                      alt='Mobile network operator logo'
                    />
                  </a>
                </div>
              )}
            </div>
          </Col>
        )}

        <Col xs={12}>{registrationState}</Col>
      </Row>
    </Container>
  )
}
