import { useEffect, useRef, useState } from 'react'
import { FutureResult } from 'model/FutureResult'
import { ActiveSuspendedStateData, ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'
import { useAppDispatch, useAppSelector, useAppStore } from 'store/redux-hooks'
import { ERROR, isOnErrorPage, NOT_FOUND, NOT_SUPPORTED, UNAUTHORIZED } from 'utils/path'
import { useLang, useVinFromState } from './hooks'
import { fetchConnectivityProductUIState } from 'features/connectivity-product-ui-state/connectivity-product-ui-state-slice'
import { ReduxStore } from 'store/store'
import { concatMap, delay, from, interval, map, of } from 'rxjs'
import { useLocation, useNavigate } from 'react-router-dom'

export const useConnectivityProductUIState = (): FutureResult<ConnectivityProductUIState> => {
  const latestState = useAppSelector((state) => state.connectivityProductUIState.value)
  const [state, setState] = useState<FutureResult<ConnectivityProductUIState>>(latestState)

  const lang = useLang()
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const vin = useVinFromState()
  const navigateRef = useRef({ navigate: useNavigate() })
  const searchRef = useRef({ search: useLocation().search })

  useEffect(() => {
    setState(latestState)
  }, [latestState])

  useEffect(() => {
    const navigate = navigateRef.current.navigate

    if (vin === '') {
      navigate({
        pathname: `/${lang}/${NOT_FOUND}`,
        search: searchRef.current.search,
      })
      return
    }

    if (state.data || isOnErrorPage()) {
      return
    }

    const fetchState = async (): Promise<FutureResult<ConnectivityProductUIState>> => {
      try {
        const state = await dispatch(fetchConnectivityProductUIState({ languageTag: lang })).unwrap()
        setState(state)
        if (state.data?.state === 'NOT_SUPPORTED') {
          navigate({
            pathname: `/${lang}/${NOT_SUPPORTED}`,
            search: searchRef.current.search,
          })
        }
        if (isInStateTransition(state.data)) {
          refreshProductState(store, lang)
        }
        return state
      } catch (error) {
        const message = (error as { message: string }).message

        let status: number
        if (message.includes('403')) {
          status = 403
        } else if (message.includes('404')) {
          status = 404
        } else {
          status = message.includes('403') ? 403 : 500
        }

        return Promise.reject({ response: { status } })
      }
    }

    fetchState()
      .then((product) => {
        if (!product.data) {
          return Promise.reject()
        }
        return Promise.all([Promise.resolve(product.data)])
      })
      .catch((rejected) => {
        if (!rejected) {
          navigate({
            pathname: `/${lang}/${NOT_FOUND}`,
            search: searchRef.current.search,
          })
        } else if (rejected.response?.status === 403) {
          navigate({
            pathname: `/${lang}/${UNAUTHORIZED}`,
            search: searchRef.current.search,
          })
        } else if (rejected.response?.status === 404) {
          navigate({
            pathname: `/${lang}/${NOT_SUPPORTED}`,
            search: searchRef.current.search,
          })
        } else {
          navigate({
            pathname: `/${lang}/${ERROR}`,
            search: searchRef.current.search,
          })
        }
      })
  }, [lang, dispatch, state.data, store, vin])

  return state
}

export const isInStateTransition = (productUiState?: ConnectivityProductUIState): boolean =>
  productUiState?.dataPlanStatus === 'IN_ACTIVATION' ||
  productUiState?.accountStatus === 'IN_CREATION' ||
  productUiState?.accountStatus === 'CREATION_OVERDUE' ||
  productUiState?.accountStatus === 'IN_ACTIVATION' ||
  (productUiState as ActiveSuspendedStateData)?.suspensionStatus === 'IN_SUSPENSION' ||
  (productUiState as ActiveSuspendedStateData)?.suspensionStatus === 'IN_ACTIVATION'

export const refreshProductState = (store: ReduxStore, lang?: string): void => {
  const subscription = interval(0)
    .pipe(
      concatMap((count) => of(count).pipe(delay(5000))),
      concatMap(() => from(store.dispatch(fetchConnectivityProductUIState({ languageTag: lang })).unwrap())),
      map((connectivityState) => connectivityState.data)
    )
    .subscribe({
      next: (state) => {
        if (!isInStateTransition(state)) {
          subscription.unsubscribe()
        }
      },
    })
}
