import { getBaseUrlForAssets, getWidgetState, initApiForWebcomponent, WidgetState } from './ApiClient'
import { getSkeletonStyle } from '@porsche-design-system/components-js/styles'
import { getTranslationForLanguage } from './IitcPortalRedirectLanguage'
import { iitcWidgetTemplateHtml } from './IitcPortalRedirectWidgetTemplate'
import { concatMap, delay, from, interval, of } from 'rxjs'
import { supportedLanguages } from '../utils/supportedLanguages'

const POLLING_INTERVAL_2S = 2000
const POLLING_INTERVAL_5S = 5000
const POLLING_INTERVAL_10S = 10000
const NUMBER_OF_RETRIES_2S = 15 // 30 seconds
const NUMBER_OF_RETRIES_5S = 5 // 30 seconds
const TOTAL_NUMBER_OF_RETRIES = 26 // 2 minutes (15(2s) + 5(5s) + 6(10s))
const template = document.createElement('template')

export class IitcPortalRedirectWidget extends HTMLElement {
  private _shadowRoot: ShadowRoot
  private widgetState?: WidgetState
  private initializedEvent = new CustomEvent('initialized', {})
  private isModalOpen = false

  public constructor() {
    super()
    this._shadowRoot = this.attachShadow({ mode: 'open' })
    this._shadowRoot.appendChild(template.content.cloneNode(true))
  }

  public async connectedCallback(): Promise<void> {
    if (!this.token || !this.vin || !this.country) {
      return
    }
    this._shadowRoot.innerHTML = iitcWidgetTemplateHtml
    this.showSkeletonLoader()

    initApiForWebcomponent(this.token, this.environment)
    await this.fetchWidgetState(this.vin, this.country)
    this.hideSkeletonLoader()
    this.setUpContainer()
    this.showWidgetContent()
    this.setUpWidgetButton()
    this.setUpModal()
    await this.setUpTranslations()
    this.setUpProviderNameInSubheader()
    this.dispatchEvent(this.initializedEvent)
  }

  private fetchWidgetState = async (vin: string, country: string): Promise<void> => {
    this.widgetState = await getWidgetState(vin, country)
    const subscription = interval(0)
      .pipe(
        concatMap((count) => of(count).pipe(delay(calculateDelay(count)))),
        concatMap((count) => from(Promise.all([getWidgetState(vin, country), count])))
      )
      .subscribe({
        next: (widgetState) => {
          this.widgetState = widgetState[0]
          const count = widgetState[1]
          if (this.isPortalAvailable() || this.isActivationError()) {
            this.setUpModal()
            this.setUpWidgetButton()
            subscription.unsubscribe()
          }
          if (this.isAccountCreationOverdue() || count > TOTAL_NUMBER_OF_RETRIES) {
            this.widgetState = this.getErrorState()
            this.setUpModal()
            subscription.unsubscribe()
          }
        },
      })

    const calculateDelay = (count: number): number => {
      if (count < NUMBER_OF_RETRIES_2S) {
        return POLLING_INTERVAL_2S
      }
      if (count < NUMBER_OF_RETRIES_2S + NUMBER_OF_RETRIES_5S) {
        return POLLING_INTERVAL_5S
      }
      return POLLING_INTERVAL_10S
    }
  }

  private getErrorState(): WidgetState {
    return {
      ...this.widgetState,
      // @ts-ignore
      productState: {
        ...this.widgetState?.productState,
        productActivationError: { type: 'CREATE_ACCOUNT', code: 'MNO_SERVER_ERROR' },
      },
    }
  }

  private setUpWidgetButton(): void {
    this.setUpProviderNameOnWidgetButton()
    this.setUpOnClickOnWidgetButton()
  }

  private setUpProviderNameOnWidgetButton(): void {
    const providerName = this.widgetState?.mnoInfo?.shortDisplayName ?? ''
    const goToButton = this._shadowRoot.getElementById('go-to-button')
    if (goToButton) {
      goToButton.innerHTML = goToButton.innerHTML.replace(/{{providerName}}/g, providerName)
    }
  }

  private setUpOnClickOnWidgetButton(): void {
    const goToButton = this._shadowRoot.getElementById('go-to-button')
    if (!goToButton) {
      return
    }
    if (this.isPortalAvailable()) {
      goToButton.onclick = () => window.open(this.widgetState?.mnoRefs?.registerAccount, '_blank')
    } else {
      goToButton.onclick = () => this.showModal(true)
    }
  }

  private async setUpTranslations(): Promise<void> {
    const preferredLanguage = this.language || 'en'
    const language = this.isLanguageSupported(preferredLanguage) ? preferredLanguage : 'en'
    const allTranslations = await getTranslationForLanguage(language)
    const widgetTranslations = Object.entries(allTranslations).filter(([key]) => key.startsWith('iitc.widget'))
    for (const [key, value] of widgetTranslations) {
      const element = this._shadowRoot.querySelector(`[translation-key='${key}']`)
      if (element) {
        element.innerHTML = value + element.innerHTML
      }
    }
    /* eslint-disable-next-line */
    const modal = this._shadowRoot.getElementById('iitc-widget-modal') as any
    modal.heading = widgetTranslations.find(([key]) => key === 'iitc.widget.modal.heading')?.[1]

    /* eslint-disable-next-line */
    const modalErrorNotification = this._shadowRoot.getElementById('iitc-widget-modal-error') as any
    modalErrorNotification.heading = widgetTranslations.find(([key]) => key === 'iitc.widget.modal.error.heading')?.[1]
    modalErrorNotification.description = widgetTranslations.find(
      ([key]) => key === 'iitc.widget.modal.error.description'
    )?.[1]
  }

  private isLanguageSupported(language: string): boolean {
    return supportedLanguages.includes(language)
  }

  private setUpProviderNameInSubheader(): void {
    const providerName = this.widgetState?.mnoInfo?.displayName ?? ''
    const subheader = this._shadowRoot.getElementById('subheader')
    if (subheader) {
      subheader.innerHTML = subheader.innerHTML.replace(/{{providerName}}/g, providerName)
    }
  }

  private setUpContainer(): void {
    const container = this._shadowRoot.getElementById('iitc-widget-container') as HTMLElement
    container.style.display = this.isPortalAvailable() || this.isWaitingForAccountToBeCreated() ? 'flex' : 'none'
  }

  private isWaitingForAccountToBeCreated = (): boolean => {
    const accountStatus = this.widgetState?.productState?.accountStatus
    return 'INACTIVE' === accountStatus || 'IN_CREATION' === accountStatus
  }

  private isPortalAvailable = (): boolean => {
    return 'IN_ACTIVATION' === this.widgetState?.productState?.accountStatus
  }

  private isActivationError(): boolean {
    return !!this.widgetState?.productState?.productActivationError
  }

  private isAccountCreationOverdue = (): boolean => {
    return 'CREATION_OVERDUE' === this.widgetState?.productState?.accountStatus
  }

  private showSkeletonLoader(): void {
    const skeleton = this._shadowRoot.getElementById('iitc-widget-container') as HTMLElement
    const skeletonStyle = getSkeletonStyle({ theme: 'light' })
    skeleton.style.display = skeletonStyle.display
    skeleton.style.background = skeletonStyle.background
    skeleton.style.animation = skeletonStyle.animation
    skeleton.style.height = '150px'
  }

  private hideSkeletonLoader(): void {
    const skeleton = this._shadowRoot.getElementById('iitc-widget-container') as HTMLElement
    skeleton.style.background = ''
    skeleton.style.animation = ''
    skeleton.style.height = ''
  }

  private showWidgetContent(): void {
    const widgetContent = this._shadowRoot.getElementById('widget-content') as HTMLElement
    widgetContent.style.display = 'flex'
  }

  /* eslint-disable */
  private setUpModal = () => {
    const modal = this._shadowRoot.getElementById('iitc-widget-modal') as any
    if (modal) {
      modal.addEventListener('dismiss', () => this.showModal(false))
    }
    this.setUpMnoDisplayNameInModal()
    this.setUpMnoLogoInModal()

    this.setUpModalLoading()
    this.setUpModalError()
    this.setUpModalSuccess()
  }

  private setUpModalLoading(): void {
    const modalLoadingContent = this._shadowRoot.getElementById('iitc-widget-modal-info-loading')
    if (modalLoadingContent) {
      if (this.isPortalAvailable() || this.isActivationError()) {
        modalLoadingContent.style.display = 'none'
      } else {
        modalLoadingContent.style.display = 'flex'
      }
    }
  }

  private setUpModalSuccess(): void {
    if (!this.isPortalAvailable()) {
      return
    }
    const modalContentCreated = this._shadowRoot.getElementById('iitc-widget-modal-info-created')
    if (modalContentCreated) {
      modalContentCreated.style.display = 'flex'
    }
    const continueButton = this._shadowRoot.getElementById('continue-button')
    if (continueButton) {
      continueButton.onclick = () => window.open(this.widgetState?.mnoRefs?.registerAccount, '_blank')
    }
  }

  private showModal = (isShown: boolean): void => {
    const container = this._shadowRoot.getElementById('iitc-widget-modal') as any
    this.isModalOpen = isShown
    container.open = this.isModalOpen
  }

  private setUpMnoDisplayNameInModal() {
    const providerName = this._shadowRoot.getElementById('iitc-widget-modal-provider-name') as HTMLAnchorElement
    if (providerName && this.widgetState?.mnoInfo) {
      providerName.innerHTML = this.widgetState?.mnoInfo.displayName
    }
    if (providerName && this.widgetState?.mnoRefs) {
      providerName.href = this.widgetState?.mnoRefs.contactDetails?.imprint || ''
    }
  }

  private setUpMnoLogoInModal() {
    const logo = this._shadowRoot.getElementById('iitc-widget-modal-logo') as HTMLImageElement
    if (logo && this.widgetState?.mnoInfo) {
      logo.src =
        getBaseUrlForAssets(this.environment) + `/assets/logo/${this.widgetState?.mnoInfo.mno.toLowerCase()}/1x.png`
    }

    const logoHref = this._shadowRoot.getElementById('iitc-widget-modal-logo-href') as HTMLAnchorElement
    if (logoHref && this.widgetState?.mnoRefs) {
      logoHref.href = this.widgetState?.mnoRefs.contactDetails?.imprint || ''
    }
  }

  private setUpModalError() {
    if (this.isActivationError() || this.isAccountCreationOverdue()) {
      const errorWrapper = this._shadowRoot.getElementById('iitc-widget-modal-error-wrapper') as HTMLElement
      errorWrapper.style.display = 'flex'
    }
  }

  get vin(): string | null {
    return this.getAttribute('vin')
  }

  get country(): string | null {
    return this.getAttribute('country')
  }

  get language(): string | null {
    return this.getAttribute('language')
  }

  get token(): string | null {
    return this.getAttribute('token')
  }

  get environment(): string | null {
    return this.getAttribute('environment')
  }
}

customElements.define('iitc-portal-redirect-widget', IitcPortalRedirectWidget)
