import React, { lazy, Suspense, useMemo } from 'react'
import { TopBar } from 'components/top-bar/TopBar'
import { ERROR_RESULT, FutureResult } from 'model/FutureResult'
import { Route, Routes } from 'react-router-dom'
import { ACTIVATION_ERROR, NOT_REGISTERED, REGISTERED } from 'utils/path'
import { GuardedRoute } from 'components/guarded-route/GuardedRoute'
import JpPopover from 'components/jp-rel4/popover/JpPopover'
import 'components/CommonStyles.scss'
import { Col } from 'react-bootstrap'
import { ConnectivityProductUIState, ErrorStateData } from 'model/uistate/ConnectivityProductUIState'
import { usePath } from 'hooks/hooks'
import { SoftbankRefs } from 'api/JpApi'
import WrappedSpinner from '../../wrapped-spinner/WrappedSpinner'

const JpRel4Page = ({
  connectivityProductState,
}: {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}): JSX.Element => {
  const Registered = useMemo(() => lazy(() => import('components/jp-rel4/jp-registered/JpRegistered')), [])
  const NotRegistered = useMemo(() => lazy(() => import('components/jp-rel4/jp-not-registered/JpNotRegistered')), [])
  const ActivationError = useMemo(() => lazy(() => import('components/activation-error/ActivationError')), [])

  const path = usePath(connectivityProductState)

  return (
    <Col xs={{ span: 10, offset: 1 }} data-testid='mno-content-jp-rel4'>
      <TopBar
        refs={ERROR_RESULT.data}
        popover={<JpPopover refs={connectivityProductState.data?.refs as SoftbankRefs} />}
        displayProviderInfo={false}
      />
      <div>
        <Suspense fallback={<WrappedSpinner aria={{ 'aria-label': 'Redirecting to page' }} />}>
          <Routes>
            <Route
              path={`/${REGISTERED}`}
              element={
                <GuardedRoute loading={connectivityProductState.pending} authorized={path === REGISTERED} redirectTo=''>
                  <Registered productState={connectivityProductState} />
                </GuardedRoute>
              }
            />
            <Route
              path={`/${NOT_REGISTERED}`}
              element={
                <GuardedRoute
                  loading={connectivityProductState.pending}
                  authorized={path === NOT_REGISTERED}
                  redirectTo=''
                >
                  <NotRegistered refs={connectivityProductState?.data?.refs} />
                </GuardedRoute>
              }
            />
            <Route
              path={`/${ACTIVATION_ERROR}`}
              element={
                <GuardedRoute
                  loading={connectivityProductState.pending}
                  authorized={path === ACTIVATION_ERROR}
                  redirectTo=''
                >
                  <ActivationError activationError={(connectivityProductState as ErrorStateData)?.productError} />
                </GuardedRoute>
              }
            />
          </Routes>
        </Suspense>
      </div>
    </Col>
  )
}

export default JpRel4Page
