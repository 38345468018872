import { getApi } from 'api/Api'
import { components } from 'api/generated-schema'
import {
  ACTIVATION_ERROR,
  INACTIVE,
  LITE,
  NOT_REGISTERED,
  NOT_SUPPORTED,
  PENDING,
  PREMIUM,
  REGISTERED,
} from 'utils/path'
import {
  ConnectivityProductUIState,
  InactiveExclusiveStateData,
  InactiveInclusiveStateData,
} from 'model/uistate/ConnectivityProductUIState'

export type DataBalance = components['schemas']['DataBalanceDTO']
export type DataUnit = DataBalance['totalBalance']['dataUnit']
export type TimePeriodUnit = components['schemas']['DataPlanInfoDTO']['timePeriodUnit']
export type Provider = components['schemas']['DataPlanInfoDTO']['provider']
export type LanguageTag = components['schemas']['LanguageTag']
export type ProductActivationError = components['schemas']['ProductActivationError']

const getEuCaPath = (connectivityProductData: ConnectivityProductUIState): string => {
  if (connectivityProductData.state === 'ACTIVE' || connectivityProductData.state === 'ACTIVE_SUSPENDED') {
    return REGISTERED
  } else if (connectivityProductData.state === 'INACTIVE_INCLUSIVE') {
    return (connectivityProductData as InactiveInclusiveStateData).accountStatus === 'UNVERIFIED'
      ? PENDING
      : NOT_REGISTERED
  } else if (connectivityProductData.state === 'INACTIVE_EXCLUSIVE') {
    return (connectivityProductData as InactiveExclusiveStateData).accountStatus === 'UNVERIFIED'
      ? PENDING
      : NOT_REGISTERED
  } else if (connectivityProductData.state === 'ERROR') {
    return ACTIVATION_ERROR
  } else {
    return NOT_SUPPORTED
  }
}

const getUsPath = (connectivityProductData: ConnectivityProductUIState): string => {
  if (connectivityProductData.state === 'INACTIVE_INCLUSIVE') {
    return INACTIVE
  } else if (connectivityProductData.state === 'INACTIVE_EXCLUSIVE') {
    return INACTIVE
  } else if (connectivityProductData.state === 'PREMIUM') {
    return PREMIUM
  } else if (connectivityProductData.state === 'LITE') {
    return LITE
  } else {
    return NOT_SUPPORTED
  }
}

const getJpPath = (connectivityProductData: ConnectivityProductUIState): string => {
  if (connectivityProductData.state === 'ACTIVE') {
    return REGISTERED
  } else if (
    connectivityProductData.state === 'INACTIVE_INCLUSIVE' ||
    connectivityProductData.state === 'INACTIVE_EXCLUSIVE'
  ) {
    return NOT_REGISTERED
  } else if (connectivityProductData.state === 'ERROR') {
    return ACTIVATION_ERROR
  } else {
    return NOT_SUPPORTED
  }
}

export const getPath = (connectivityProductData?: ConnectivityProductUIState): string => {
  switch (connectivityProductData?.connectivityProduct) {
    case 'EU_RELEASE4_CONNECTIVITY':
    case 'CA_RELEASE4_CONNECTIVITY':
      return getEuCaPath(connectivityProductData)
    case 'US_UNLIMITED_CONNECTIVITY':
      return getUsPath(connectivityProductData)
    case 'JP_RELEASE4_CONNECTIVITY':
      return getJpPath(connectivityProductData)
    default:
      return NOT_SUPPORTED
  }
}

export const isCreatingAccount = (productUiState?: ConnectivityProductUIState): boolean => {
  return (
    productUiState?.accountStatus === 'IN_CREATION' ||
    productUiState?.accountStatus === 'IN_ACTIVATION' ||
    productUiState?.accountStatus === 'CREATION_OVERDUE'
  )
}
export const isAutoBind = (stateData?: InactiveInclusiveStateData): boolean => {
  return stateData?.accountStatus === 'ACTIVE' && stateData?.dataPlanStatus === 'INACTIVE'
}
export const getLanguage = (): Promise<LanguageTag> =>
  getApi()
    ?.get<LanguageTag>('/v1/profile/language/default')
    .then((res) => res.data)

export const getConnectivityProductUIState = (vin: string, languageTag?: string): Promise<ConnectivityProductUIState> =>
  getApi()
    ?.get<ConnectivityProductUIState>('/v1/sim/product/state', {
      params: { vin },
      headers: { 'Accept-Language': languageTag },
    })
    .then((res) => res.data)
