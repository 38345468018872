import { PHeading, PIcon } from '@porsche-design-system/components-react'
import 'components/registration-state/RegistrationState.scss'
import { FormattedMessage } from 'react-intl'
import { ACTIVATION_ERROR, INACTIVE, REGISTERED } from 'utils/path'

export const RegistrationState = ({
  registrationState,
  children,
}: {
  registrationState?: string
  children?: JSX.Element
}): JSX.Element => {
  const renderRegistrationState = (registrationState?: string): JSX.Element => {
    switch (registrationState?.toLowerCase()) {
      case REGISTERED:
      case INACTIVE:
        return (
          <div className='centered-flex' data-testid='registered-state'>
            <PIcon name='check' aria={{ 'aria-label': 'Check icon' }} className='status-icon' />
            <PHeading size='medium'>
              <FormattedMessage id='app.registrationState.registered' />
            </PHeading>
          </div>
        )
      case ACTIVATION_ERROR:
        return (
          <div className='centered-flex' data-testid='not-registered-state'>
            <span className='dot red' />
            <PHeading size='medium'>
              <FormattedMessage id='app.registrationState.notRegistered' />
            </PHeading>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      <div data-testid='registration-state' className={'child-component registration-state-flex'}>
        {renderRegistrationState(registrationState)}
      </div>

      {children && (
        <div className='child-component' data-testid='suspension-state'>
          {children}
        </div>
      )}
    </div>
  )
}
