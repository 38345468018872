import { PSpinner, SelectedAriaAttributes } from '@porsche-design-system/components-react'
import './WrapperSpinner.scss'
import React from 'react'

interface WrapperSpinnerProps {
  size?: string | undefined
  aria?: SelectedAriaAttributes<'aria-label'> | undefined
  children?: JSX.Element
}

const WrappedSpinner = ({ size, aria, children }: WrapperSpinnerProps): JSX.Element => {
  return (
    <div className='spinner-wrapper'>
      <PSpinner size={size ?? 'large'} aria={aria} data-testid='spinner' />
      {children}
    </div>
  )
}

export default WrappedSpinner
