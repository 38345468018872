import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface VinState {
  value: string
}

const initialState: VinState = {
  value: new URLSearchParams(window.location.search).get('vin') ?? '',
}

export const vinSlice = createSlice({
  name: 'vin',
  initialState,
  reducers: {
    setVin: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
})

export const { setVin } = vinSlice.actions

export default vinSlice.reducer
