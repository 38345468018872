import 'components/footer/footer-badges/FooterBadges.scss'
import { PHeading } from '@porsche-design-system/components-react'
import React from 'react'
import { Container } from 'react-bootstrap'
import { BadgeModel } from 'model/footer/BadgeModel'

interface FooterBadgesProps {
  title: string
  badges: BadgeModel[]
}

export const FooterBadges = ({ title, badges }: FooterBadgesProps): JSX.Element => {
  return (
    <div className='footer-badge'>
      <PHeading size='large' className='footer-group-header' color='inherit'>
        {title}
      </PHeading>

      <Container fluid className='badges'>
        {badges.map((badge) => (
          <div className='badge' key={badge.clickEventName}>
            <a href={badge.url} data-testid='footer-badge'>
              <img src={badge.media.file.url} alt={badge.media.title} />
            </a>
          </div>
        ))}
      </Container>
    </div>
  )
}
