import React, { lazy, Suspense, useMemo } from 'react'
import { PSpinner } from '@porsche-design-system/components-react'
import { Route, Routes } from 'react-router-dom'
import { INACTIVE, LITE, PREMIUM } from 'utils/path'
import { GuardedRoute } from 'components/guarded-route/GuardedRoute'
import { Col } from 'react-bootstrap'
import { ConnectivityProductUIState } from 'model/uistate/ConnectivityProductUIState'
import { FutureResult } from 'model/FutureResult'
import { usePath } from 'hooks/hooks'
import { VerizonRefs } from 'api/UsApi'
import WrappedSpinner from '../../wrapped-spinner/WrappedSpinner'

const UsRel4Page = ({
  connectivityProductState,
}: {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}): JSX.Element => {
  const UsHighSpeed = useMemo(() => lazy(() => import('components/us-rel4/us-high-speed/UsHighSpeed')), [])
  const UsLowSpeed = useMemo(() => lazy(() => import('components/us-rel4/us-low-speed/UsLowSpeed')), [])
  const UsInactive = useMemo(() => lazy(() => import('components/us-rel4/us-inactive/UsInactive')), [])
  const refs = connectivityProductState.data?.refs as VerizonRefs
  const path = usePath(connectivityProductState)

  const premiumAvailable = (): boolean => {
    if (connectivityProductState.data && 'premiumAvailable' in connectivityProductState.data) {
      return connectivityProductState.data.premiumAvailable as boolean
    }
    return false
  }

  return (
    <>
      <Col xs={{ span: 10, offset: 1 }} data-testid='mno-content-us-rel4'>
        <div className='content'>
          <Suspense fallback={<WrappedSpinner aria={{ 'aria-label': 'Redirecting to page' }} />}>
            <Routes>
              <Route
                path={`/${PREMIUM}`}
                element={
                  <GuardedRoute loading={connectivityProductState.pending} authorized={path === PREMIUM} redirectTo=''>
                    <UsHighSpeed productState={connectivityProductState} />
                  </GuardedRoute>
                }
              />

              <Route
                path={`/${LITE}`}
                element={
                  <GuardedRoute loading={connectivityProductState.pending} authorized={path === LITE} redirectTo=''>
                    <UsLowSpeed refs={refs} premiumAvailable={premiumAvailable()} />
                  </GuardedRoute>
                }
              />

              <Route
                path={`/${INACTIVE}`}
                element={
                  <GuardedRoute loading={connectivityProductState.pending} authorized={path === INACTIVE} redirectTo=''>
                    <UsInactive refs={refs} premiumAvailable={premiumAvailable()} />
                  </GuardedRoute>
                }
              />
            </Routes>
          </Suspense>
        </div>
        {connectivityProductState.pending && (
          <PSpinner size='large' aria={{ 'aria-label': 'Loading mno refs' }} data-testid='spinner' />
        )}
      </Col>
    </>
  )
}

export default UsRel4Page
