import { PBanner } from '@porsche-design-system/components-react'
import React, { useEffect, useState } from 'react'
import 'components/error/error-banner/ErrorBanner.scss'
import { Observable } from 'rxjs'
import { FormattedMessage } from 'react-intl'

export const ErrorBanner = ({ httpError$ }: { httpError$: Observable<void> }): JSX.Element => {
  const [httpError, setHttpError] = useState(false)

  useEffect(() => {
    const subscription = httpError$.subscribe(() => setHttpError(true))

    return () => subscription.unsubscribe()
  }, [httpError$])

  return (
    <PBanner state='error' onDismiss={() => setHttpError(false)} data-testid='error-banner' open={httpError}>
      <span slot='heading' data-testid='error-banner-title'>
        <FormattedMessage id='app.errorBanner.title' />
      </span>
      <span slot='description' data-testid='error-banner-message'>
        <FormattedMessage id='app.errorBanner.message' />
      </span>
    </PBanner>
  )
}
